/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum LeaseVariationKind {
    CHANGED = 'changed',
    ADDED = 'added',
    REMOVED = 'removed',
    STATUS_CHANGED = 'statusChanged',
}
